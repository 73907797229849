import React, { useContext, useEffect, useState } from 'react';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import GoogleAutoComplete from 'components/GoogleAutoComplete';
import QueryString from 'qs';
import { useRouter } from 'next/router';
import { debounce } from 'utils/helpers/restaurants';

function RestaurantAddressInput() {
  const [internalAddress, setInternalAddress] = useState(null);
  const [addressError, setAddressError] = useState(false);
  const [completeAddressError, setCompleteAddressError] = useState(false);
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const { address, completeAddress } = userStore;
  const router = useRouter();

  useEffect(() => {
    setInternalAddress({ address, completeAddress });
  }, [address]);

  const handleOnChange = e => {
    if (addressError) setAddressError(false);
    if (completeAddressError) setCompleteAddressError(false);

    setInternalAddress({ address: e?.target?.value });
  };

  const handleOnBlur = debounce(() => {
    if (!internalAddress?.completeAddress?.city) {
      setInternalAddress({ address, completeAddress });
    }
  }, 1000);

  const setInQuery = address => {
    router.replace(
      {
        pathname: router?.pathname,
        query: {
          ...QueryString.parse(router?.query),
          address,
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  return (
    <div className="flex w-full md:mr-2 h-12">
      <GoogleAutoComplete
        className={classNames(
          'input-light-1 outline-none focus:outline-none address-inputs font-inter-medium my-0 rounded-lg text-md w-full bg-background pl-4',
          {
            'border-attention border': addressError,
          }
        )}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        value={internalAddress?.address}
        onPlaceSelected={place => {
          const _address = place?.address_components?.reduce(
            (seed, { long_name, types }) => (types.forEach(t => (seed[t] = long_name)), seed),
            {}
          );

          store.userStore?.getAndSetTimezone(place?.formatted_address);

          setInQuery(place?.formatted_address);

          const completeAddress = {
            suite: _address?.subpremise,
            street_number: _address?.street_number,
            street_name: _address?.route,
            city: _address?.locality,
            province: _address?.administrative_area_level_1,
            postal_code: _address?.postal_code,
          };

          store.userStore?.setAddress(place?.formatted_address, completeAddress);
          setInternalAddress({ address: place?.formatted_address, completeAddress });
        }}
      />
    </div>
  );
}

export default observer(RestaurantAddressInput);
