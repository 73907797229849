import AddressInput from 'components/Shared/AddressInput';
import { StoreContext } from 'pages/_app';
import React, { useContext } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { CartBucket } from 'assets/img';
import { toJS } from 'mobx';
import DeliveryPickupTabs from 'components/DeliveryPickupTabs';
import pluralize from 'pluralize';
import { useRouter } from 'next/router';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import { dateFormat, getOperatingHoursOptionsForDropdown } from 'utils/helpers/dateTime';
import { changeSpacesToHyphens } from 'utils/helpers/restaurants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { observer } from 'mobx-react';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import RestaurantAddressInput from 'components/Shared/RestaurantAddressInput';

function Filters({ getAvailableDates, getAvailableTimes, isCart, restaurantDetail }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const cartStore = toJS(store?.cartStore);
  const restaurantStore = toJS(store?.restaurantStore);
  let { cart } = cartStore;
  const { address, selectedDate, selectedTime, availableDates, addressTimezone } = userStore;

  const cartLength = cart?.items?.length;

  const { activeRestaurant } = restaurantStore;

  const router = useRouter();

  const moveToRestaurantDetails = () => {
    store.userStore.setLoader(true);

    router.push(
      `/restaurant/${cart?.restaurant?.attributes?.code}/${changeSpacesToHyphens(
        cart?.restaurant?.attributes?.name
      )}?${
        cart?.data?.attributes?.source_of_business == 'restaurant' ? 'utm_source=odc&' : ''
      }date=${moment(cart?.cartDate).format(dateFormat)}&time=${cart?.cartTime}`
    );
  };

  const getTimePickerOptions = () => {
    return getOperatingHoursOptionsForDropdown(restaurantDetail, cart?.cartDate, addressTimezone);
  };

  return (
    <>
      {/* Desktop */}
      <div className="hidden md:flex md:sticky md:top-0 z-20 border-b-2 md:mb-2 border-background bg-white mr-[5px]">
        <div className="flex w-full bg-white md:mx-auto px-8 py-2">
          <div style={{ width: '18rem' }} className="py-2 h-12" key={address}>
            {isCart ? <RestaurantAddressInput /> : <AddressInput />}
          </div>

          <div className="flex py-2">
            <DatePicker
              fromFilters
              isCart={isCart ?? false}
              availableDates={availableDates}
              activeRestaurant={activeRestaurant}
              getAvailableDates={getAvailableDates}
              getAvailableTimes={getAvailableTimes}
              selectedDate={isCart ? cart?.cartDate : selectedDate}
              minDate={new Date()}
              format={'MMMM d, yyyy'}
              className="flex h-12 bg-background justify-between rounded-lg ml-2 pr-2 items-center"
              pickerClassName={'font-inter-medium'}
            />
          </div>

          <div className="w-auto py-2 mx-2" style={{ maxWidth: '160px' }}>
            <TimePicker
              isCart={isCart ?? false}
              labelName="time-filter"
              selectedDate={isCart ? cart?.cartDate : selectedDate}
              selectedTime={isCart ? cart?.cartTime : selectedTime}
              selectPicker={{
                options: getTimePickerOptions(),
              }}
              className="h-12 w-full bg-background justify-between rounded-lg text-sm border-0 text-primary-black"
            />
          </div>

          <div className="py-2 h-12">
            <DeliveryPickupTabs />
          </div>

          <div className="flex items-end justify-end flex-grow py-2 ml-2">
            <div
              onClick={() => {
                if (cartLength > 0) moveToRestaurantDetails();
              }}
              className={classNames(
                'hidden md:flex items-center justify-center bg-background rounded-md md:w-32 h-12',
                { 'cursor-pointer': cartLength > 0 }
              )}>
              <Image src={CartBucket} alt="cart-icon-filters" />

              <span className="text-sm text-primary-black pl-2 font-inter-medium text-md">
                {cartLength} {pluralize('Item', cartLength)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Tablet */}
      <div className="hidden w-full sticky top-[10px] z-30 customMd:flex md:hidden border-b-2 border-background">
        <div className="flex flex-col w-full bg-white px-4 py-2">
          <div className="flex w-full py-2">
            {isCart ? <RestaurantAddressInput /> : <AddressInput />}
          </div>

          <div className="flex justify-between">
            <div className="flex w-auto py-2" style={{ minWidth: '110px' }}>
              <TimePicker
                labelName="time-filter"
                selectedDate={isCart ? cart?.cartDate : selectedDate}
                isCart={isCart ?? false}
                selectedTime={isCart ? cart?.cartTime : selectedTime}
                selectPicker={{
                  options: getTimePickerOptions(),
                }}
                className="h-12 bg-background justify-between rounded-lg mr-2 text-sm border-0 text-primary-black"
              />
            </div>

            <div className="flex py-2">
              <DatePicker
                fromFilters
                isCart={isCart ?? false}
                minDate={new Date()}
                availableDates={availableDates}
                activeRestaurant={activeRestaurant}
                getAvailableDates={getAvailableDates}
                getAvailableTimes={getAvailableTimes}
                selectedDate={isCart ? cart?.cartDate : selectedDate}
                className="flex h-12 bg-background justify-between rounded-lg mx-2 pr-2 items-center"
                pickerClassName={'font-inter-medium'}
              />
            </div>

            <div className="flex m-2 mr-0 mx-0">
              <DeliveryPickupTabs minWidth={'130px'} />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="flex z-30 w-full customMd:hidden md:hidden border-b border-background">
        <div className="flex flex-col w-full flex-wrap bg-white px-4 py-2">
          <div className="flex w-full pt-2">
            <div className="flex font-inter-medium my-0 rounded-lg text-md w-full border-0 bg-background pl-4 py-[14px] outline-none focus:outline-none">
              <FontAwesomeIcon
                onClick={() => {
                  if (isCart)
                    store?.userStore?.setDeliveryPickupModal({
                      state: true,
                      fromCart: true,
                    });
                  else store?.userStore?.setMobileDateTimeModal(true);
                }}
                size="1x"
                icon={faPencilAlt}
                className="icon mr-2"
              />
              <span>{address}</span>
            </div>
          </div>

          {/* <div className="flex justify-between space-x-3">
            <div className="flex w-1/2 py-2">
              <DatePicker
                fromFilters
                isCart={isCart ?? false}
                minDate={new Date()}
                availableDates={availableDates}
                activeRestaurant={activeRestaurant}
                getAvailableDates={getAvailableDates}
                getAvailableTimes={getAvailableTimes}
                selectedDate={isCart ? cart?.cartDate : selectedDate}
                dateFormat={"MMM d, yyyy"}
                className="flex h-12 w-full bg-background rounded-lg items-center pr-2"
                pickerClassName={`rounded-md w-[calc(100%-2rem)] pl-3 py-1 bg-background cursor-pointer font-inter-medium text-primary-black text-left focus:outline-none`}
              />
            </div>

            <div className="flex w-1/2 py-2">
              <TimePicker
                selectedDate={isCart ? cart?.cartDate : selectedDate}
                isCart={isCart ?? false}
                selectedTime={isCart ? cart?.cartTime : selectedTime}
                selectPicker={{
                  options: getTimePickerOptions()
                }}
                className="h-12 w-full overflow-hidden bg-background justify-between rounded-lg border-0 text-primary-black"
              />
            </div>
          </div> */}

          {/* <div className="flex w-full pt-2">
            <DeliveryPickupTabs
              minWidth={"70px"}
              tabClassName={classNames(
                "w-full rounded-lg py-2.5 min-[323px]:text-sm whitespace-nowrap leading-5 text-primary-black font-inter-regular px-10",
                "ring-white outline-none focus:outline-none"
              )}
            />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default observer(Filters);
